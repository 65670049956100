export const awsConfig = {
  Auth: {
    identityPoolId: "us-east-2:68d384e2-e2eb-4a92-b03f-2c26ca5e227c",
    region: "us-east-2",
    userPoolId: "us-east-2_SnR1sbE2o",
    userPoolWebClientId: "393mabkh2v8b3cmo41huvvr6fm",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    aws_appsync_graphqlEndpoint:
      "https://nrus4lt7lbb73a2gvmwxpxnh2y.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "asset",
        endpoint:
          "https://nrus4lt7lbb73a2gvmwxpxnh2y.appsync-api.us-east-2.amazonaws.com/graphql",
      },
      {
        name: "auctions",
        endpoint:
          "https://hrnqfwknmvdongsfqthy7rdmda.appsync-api.us-east-2.amazonaws.com/graphql",
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "prod-legalau-assets-static",
      region: "us-east-2",
    },
  },
};
